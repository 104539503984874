.progressiveImageContainer {
  width: 90vw;
  height: 90vh;
  position: relative;
}

.lowQuality {
  width: 100%;
  filter: blur(20px);
  transition: opacity 0.3s ease;
}

.lowQuality.hidden {
  opacity: 0;
}

.highQuality {
  width: 100%;
  max-width: 80vw;
  max-height: 80vh;
  border: 1px solid var(--grey300);
  border-radius: 8px;
  border-top-left-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  left: 0;
  object-fit: cover;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: filter 0.5s ease;
  user-select: none;
}

.highQuality.visible {
  opacity: 1;
}

.highQuality.hidden {
  display: none;
}

@media screen  and (max-width:450px) {
  .progressiveImageContainer {
    width: auto;
    height: auto;
  }
}