.wrapper {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  width: 100%;
}

.modalContent {
  max-width: 70vw;
  max-height: 90vh;
  overflow: hidden;
}

.leftSide {
  flex: 1.25;
}

.rightSide {
  flex: 1.5;
}

.modalTitleStyle {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--secondary500);
}

.leftSideTitle {
  color: var(--grey800);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  gap: 0.5rem;
  align-items: center;
  flex-wrap: nowrap;
}

.dividerLine {
  border: 1px solid var(--grey200);
}

.opinionText {
  margin: 1rem 0 2.5rem 0;
  color: var(--grey700);
  padding-left: 4.75rem;
}

.modalIcon {
  color: var(--grey900);
}

.actionsWrapper {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 1rem;
  width: 100%;
}

.cancelBtn,
.addBtn {
  width: 8.5rem;
  height: 2.5rem;
  padding: 0.5rem 1.5rem;
}

.cancelBtn {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
}

.cancelBtn:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.proposalWrapper {
  margin: 1.5rem 0;
}

.textAreaModal {
  height: 10rem;
  border-radius: 10px;
  padding: 1rem;
  resize: none;
}

.loader {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  left: 0;
}

.generateBtn {
  background-color: transparent;
  color: var(--secondary500);
  padding: 0;
  margin-top: 1rem;
  justify-self: flex-start;

}

.generateBtn:hover {
  background-color: transparent;
  color: var(--secondary600);
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column-reverse;
  }

  .opinionText {
    padding-left: 0;
  }
}

@media screen and (max-width: 450px) {
  .actionsWrapper {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .cancelBtn,
  .addBtn {
    width: 100%;
  }
}