.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--grey300);
    border-radius: .25rem;
    padding: 1rem;
}

.wrapper>*>p {
    margin: 0
}

.dataItemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.dataItemWrapper>p,
.value>*>p {
    margin: 0;
}

.dataItemWrapper>p {
    margin: 0;
    padding: 0;
}

.value {
    margin: 0;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    margin-left: auto;
    padding: 0;
    text-transform: capitalize;
    text-wrap: wrap;
}

.key {
    margin: 0;
    color: var(--grey700);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-16);
    padding: 0;
}

.connectedBusinessProfile {
    margin-right: auto;
    text-align: left;
}

.connectedBusinessProfile>p {
    margin: 0;
    padding: 0;
}

.connectedBusinessProfile>p:nth-of-type(2) {
    color: var(--grey800);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
}



.detailsBtn {
    margin: 0;
    margin-top: 2rem;
    align-self: flex-end;
    background-color: transparent;
    border: 1px solid var(--primary500);
    color: var(--primary500);
    padding: .25rem 1.5rem;
}

.detailsBtn:hover,
.detailsBtn:focus {
    background-color: transparent;
    color: var(--primary600)
}