.container {
  height: 100%;
  background-color: var(--white);
  overflow: hidden;
  overflow-y: scroll;
}

.container::-webkit-scrollbar {
  display: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto;
  align-items: center;
  max-width: 900px;
  flex-grow: 2;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--grey100);
  inset: 0;
  opacity: 0.5;
  overflow: hidden;
  position: absolute;
  transition: all 0.5s ease;
  z-index: 50;
}

.buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 32px;
  align-items: center;
  width: 100%;
}

.button {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  border-radius: 32px;
  font-weight: var(--font-weight-medium);
  padding: 10px 24px;
}

.button:disabled:active {
  filter: none;
}

.btnBack {
  width: 175px;
  background-color: var(--grey50);
  border: 1px solid var(--grey300);
  color: var(--grey900);
}

.btnBack:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.iconStyle {
  fill: var(--white);
  stroke: var(--white);
  stroke-width: 1.6px;
  transform: rotate(180deg);
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 35%;
  z-index: 5;
}

.form {
  width: 80%;
  height: 100%;
  background-color: white;
  border-radius: 18px;
  box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(47, 47, 141, 0.09);
  padding: 32px;
  z-index: 10;
}

.availableReports {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-weight: var(--font-weight-medium);
}

@media screen and (max-width: 1024px) {
  .backgroundImage {
    height: auto;
    bottom: 0;
    top: auto;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    margin-block: 0;
  }

  .form {
    width: 100%;
    max-height: none;
    box-shadow: none;
    padding: 0;
  }

  .buttons {
    flex-direction: column-reverse;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    padding-inline: 16px;
  }

  .button {
    width: 100%;
  }

  .container {
    max-height: calc(100svh - 60px);
    box-sizing: border-box;
    padding: 1.5rem 0;
  }

  .backgroundImage {
    display: none;
  }
}
