.textarea {
  border: none;
  color: var(--grey900);
  flex: 1;
  font-family: 'Poppins';
  font-size: var(--font-size-md);
  outline: none;
  overflow-y: scroll;
  padding: 0;
  resize: none;
}

.textarea:disabled {
  background-color: var(--grey50);
  color: var(--grey600);
  white-space: pre-line;
}

.textarea::-webkit-scrollbar {
  width: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background-color: var(--grey400);
  border-radius: 8px;
}

.textAreaContainer {
  width: 100%;
}

.textAreaBox:has(.errorLabel) .label {
  color: var(--red500);
}

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--grey300);
  border-radius: 8px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.textAreaBox {
  width: 100%;
}

.wrapper.error {
  border: 1px solid var(--red500);
}

.admin > * {
  font-family: var(--manrope);
}

.wrapper.focused {
  border: 1px solid var(--primary500);
}

.admin.wrapper.focused {
  border: 1px solid var(--adminOrange300);
}

.wrapper:hover {
  border: 1px solid var(--primary300);
}

.admin.wrapper:hover {
  border: 1px solid var(--adminOrange300);
}

.wrapper.disabled {
  background-color: var(--grey50);
}

.label {
  margin-top: 5px;
  color: var(--grey700);
  font-size: var(--font-size-md);
  transition: font-size 0.3s;
  user-select: none;
}

.label.focused {
  font-size: var(--font-size-xs);
}

.label.error {
  color: var(--red500);
}

.wrapper.focused.error {
  border: 1px solid var(--red500);
}

.textarea.disabled {
  background-color: var(--grey50);
  color: var(--gray600);
  white-space: pre-line;
}

.wrapper.disabled:hover {
  border: 1px solid var(--grey300);
}

.errorLabel {
  display: flex;
  gap: 4px;
  margin-top: 8px;
  align-items: center;
  width: 100%;
  color: var(--red500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
}

.text {
  line-height: 12px;
  margin-left: 10px;
}

.inputWrapper.error {
  border: 1.5px solid var(--red400);
}

.counter {
  width: fit-content;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-16);
  margin-left: auto;
  position: relative;
  text-align: right;
  top: 0px;
}

.errorIcon {
  width: var(--icon-small);
  height: var(--icon-small);
}

@media screen and (max-width: 375px) {
  .textarea:disabled {
    color: var(--black);
  }
}
