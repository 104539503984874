.backdrop {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  background-color: rgba(125, 125, 125, 0.36);
  inset: 0;
  overflow: hidden;
  position: fixed;
  z-index: 2;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 32px auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 32px;
  z-index: 2;
}

.content::-webkit-scrollbar {
  display: none;
}

.mobileBackBox {
  display: none;
}

.content::-webkit-scrollbar-track {
  background-color: var(--grey50);
  border-radius: 4px;
}

.content::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: var(--grey400);
  border-radius: 4px;
}

.goBackIcon,
.goBackIconRWD {
  width: var(--icon-medium);
  height: var(--icon-medium);
  box-sizing: border-box;
  color: var(--grey800);
  cursor: pointer;
  padding-left: 0;
  stroke-width: 1.5px;
}

.goBackIcon:hover {
  fill: var(--primary500);
  stroke: var(--primary500);
}

.modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--grey100);
  border-radius: 18px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.closeIcon {
  width: 18px;
  height: 18px;
}

.title {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: 28px;
  padding-block: 0.5rem;
}

.text {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 2rem;
}

.showClose {
  display: block;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  max-width: 34px;
  min-width: 34px;
  max-height: 34px;
  min-height: 34px;
  aspect-ratio: 1/1;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 50%;
  cursor: pointer;
  margin-left: auto;
}

.iconWrapper:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

@media screen and (max-width: 1024px) {
  .modal {
    max-width: 80%;
    max-height: 80%;
  }
}

@media screen and (max-width: 768px) {
  body:has(.modal) {
    overflow-y: hidden;
  }

  .content {
    margin: 0 auto;
    height: calc(100svh - 134px - 24px);
    max-height: 100%;
    box-sizing: border-box;
    margin-bottom: 48px;
    overflow-y: scroll;
    padding: 12px;
    position: relative;
  }

  .backdrop {
    backdrop-filter: none;
    background-color: transparent;
    position: fixed;
    top: 80px;
  }

  .showClose {
    display: block;
  }

  .modal {
    width: 100%;
    max-width: none;
    min-width: 100vw;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
    left: 0;
    top: 0;
    transform: translateY(54px);
  }

  .mobileBackBox {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: fit-content;
    color: var(--grey700);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 450px) {
  .content {
    max-height: none;
    overflow-y: scroll;
    padding: 12px 16px;
    position: relative;
  }

  .content {
    margin: 0 auto;
    height: calc(100svh - 118px - 24px);
  }

  .backdrop {
    top: 4rem;
  }
}
