.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 24px 32px;
  height: calc(100vh - 210px);
  background-color: var(--white);
  border-radius: 12px;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--primary200);
  flex-shrink: 0;
  margin-bottom: .5rem;
  padding-bottom: 12px;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.businessProfileName {
  width: 100%;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
}

.busienssProfileAddress {
  color: var(--grey600);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
}

.businessProfileInfoWrapper {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.planName {
  color: var(--grey900);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  text-transform: capitalize;
}

.namePlan {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  text-transform: capitalize;
}

.planPrice {
  color: var(--grey600);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.paymentMethodType,
.dateLabel {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.paymentMethodPeriod,
.dateValue {
  color: var(--grey600);
  line-height: var(--line-height-15);
}

.pill {
  background-color: var(--green50);
  border: 1px solid var(--green400);
  border-radius: 1rem;
  color: var(--green900);
  padding: 4px 12px;
}

.canceled,
.rejected {
  background-color: var(--red50);
  border: 1px solid var(--red400);
  color: var(--red900);
}

.pagination {
  margin-top: auto;
  width: 100%;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.mangeBtn {
  background-color: var(--transparent);
  border: 1px solid var(--primary500);
  border-radius: 2rem;
  color: var(--primary500);
  font-size: var(--font-size-sm);
  padding: 6px 1rem;
}

.mangeBtn:hover {
  background-color: transparent;
  border-color: var(--primary600);
  color: var(--primary600);
}

@media screen and (max-width: 1440px) {
  .listWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: auto;
  }


  .list {
    width: 100vw;
    max-height: calc(100vh - 400px);
    margin-bottom: 1rem;
    overflow-x: auto;
  }

  .listWrapper::-webkit-scrollbar {
    height: 8px;
  }

  .listWrapper::-webkit-scrollbar-thumb {
    background-color: var(--grey200);
  }
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 1rem;
    height: calc(100vh - 260px);
    padding: 1rem;
  }

}

@media screen and (max-width: 768px) {
  .planPrice,
  .paymentMethodPeriod,
  .dateValue,
  .busienssProfileAddress {
    font-weight: var(--font-weight-regular);
  }

  .listWrapper {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 450px) {
  .container {
    margin: 0;
    height: calc(100vh - 220px);
  }

  .title {
    font-size: var(--font-size-md);
  }

  .header {
    flex-wrap: wrap;
  }

  .header>*:nth-child(2) {
    padding: .5rem 1.5rem;
  }
}