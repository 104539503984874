.wrapper {
  width: 400px;
  max-height: 60vh;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0px 4px 16px 0px #2f2f8d17;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  overflow-y: auto;
  padding: 1rem;
  z-index: 3;
}

::-webkit-scrollbar {
  width: 0;
}

.notificationTopbar {
  border-bottom: 1px solid var(--grey200);
  box-shadow: 0px 7px 4px 0px #00000005;
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding-bottom: 1rem;
}

.checkbox {
  gap: 0.5rem;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.checkIconStyle {
  transform: translate(1px, 0px);
}

.checkboxStyle {
  display: flex;
  flex-direction: row;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary500);
}

.showAllBtn {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.25rem;
  margin-top: 1rem;
  align-items: center;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  border-radius: 2rem;
  color: var(--grey900);
  font-size: var(--font-size-sm);
  margin-left: auto;
  padding: 6px 12px;
}

.showAllBtn:hover {
  background-color: var(--grey100);
}

.btnIcon {
  width: 18px;
  height: 18px;
  rotate: 270deg;
  stroke-width: 0.5px;
  transform: translate(2px, 1px);
}

.noData {
  color: var(--grey600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  padding-block: 1rem;
}