.noImage {
  display: flex;
  justify-content: center;
  margin: 15px auto;
  align-items: center;
  height: 270px;
  max-height: 270px;
  background-color: var(--grey100);
}

.noImageIcon {
  max-height: 4rem;
  color: var(--grey400);
}

.image {
  display: flex;
  justify-content: center;
  margin: 15px 0 25px 0;
  align-items: center;
  width: 100%;
  max-height: 270px;
  object-fit: cover;
}
