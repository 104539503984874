.wrapper:has(.imageBox) .summary {
  margin-top: 0px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 435px;
  color: var(--grey600);
  font-size: 14px;
  font-weight: 400;
  line-height: var(--line-height-16);
}

.subInfo:has(.navigateGoogleButton) {
  max-width: 635px;
}

.navigateGoogleButton {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  margin-top: 8px;
  align-items: center;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  font-size: 14px;
  font-weight: 500;
  padding: 6px 16px;
}

.navigateGoogleButton:hover {
  background-color: var(--grey100);
  border: 1px solid var(--grey400);
}

.image {
  margin-top: 24px;
  width: 100%;
  height: 340px;
  background-color: var(--grey200);
  border-radius: 8px;
  object-fit: cover;
  user-select: none;
}

.title {
  margin-top: 16px;
  color: var(--grey900);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.schedule {
  color: var(--grey600);
  line-height: var(--line-height-15);
}

.summary {
  margin-top: 12px;
  color: var(--grey900);
  line-height: var(--line-height-15);
  white-space: pre-line;
  word-wrap: break-word;
}

.horizontalLine {
  margin: 24px auto;
  width: 100%;
  height: 1px;
  background-color: var(--grey200);
}

.button {
  margin-top: 32px;
  background: var(--white);
  border: 1px solid var(--primary500);
  border-radius: 32px;
  color: var(--primary500);
  padding: 8px 20px;
  text-decoration: none;
}

.button:hover {
  background-color: var(--white);
  border-color: var(--primary600);
  color: var(--primary600);
}

.rowContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  margin-top: 12px;
  align-items: center;
  width: 100%;
}

.plannedPostInfo {
  margin-top: 8px;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}

.sectionNameStyle {
  display: flex;
}

.publishDate {
  margin-top: 8px;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-15);
}
@media screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
    height: 100%;
  }

  .rowContainer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navigateGoogleButton {
    width: 100%;
    padding: 12px 16px;
  }
}
