.wrapper {
  margin: 1.5rem;
  height: calc(100vh - 18rem);
  background-color: var(--white);
  border-radius: 0.75rem;
  overflow: hidden;
  overflow-y: auto;
  padding: 1.5rem;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.basicInformation {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 50%;
  border-bottom: 1px solid var(--grey300);
  padding-bottom: 2rem;
}

.basicInformationTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 2rem;
}

.actions {
  display: flex;
  gap: 0.75rem;
}

.actions>* {
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: 6px 16px;
}

.actions>*:hover {
  background-color: var(--grey100);
}

.label {
  color: var(--grey700);
  font-size: var(--font-size-md);
  line-height: var(--line-height-15);
  margin-bottom: 0.75rem;
}

.value {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
}

.linkInformation {
  margin-top: 1.5rem;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 0;
    height: calc(100vh - 22rem);
  }

  .basicInformation {
    max-width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .basicInformation {
    margin-top: 1rem;
    max-width: 100%;
  }

  .basicInformationTitle {
    gap: 2rem;
    flex-wrap: wrap;
    font-size: var(--font-size-md);
    margin-bottom: 0;
    row-gap: 1rem;
  }

  .actions {
    flex-wrap: wrap;
  }

  .actions>*,
  .basicInformationTitle>* {
    width: 100%;
  }

  .wrapper {
    padding-inline: 1rem;
  }
}