.modalContent {
  width: clamp(500px, 80%, 700px);
}

.modalContent > * {
  user-select: none;
}

.actionWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 20px;
}

.deleteAccBtn {
  background: transparent;
  border-color: var(--red500);
  color: var(--red500);
}

.deleteAccBtn:hover {
  background-color: transparent;
  color: var(--red600);
}

.customLoaderStyle {
  margin-block: 20px;
}

.goBackBtn {
  background-color: transparent;
  color: var(--grey800);
}

.goBackBtn:hover {
  background-color: transparent;
  color: var(--grey900);
}

@media screen and (max-width: 768px) {
  .modalContent {
    width: auto;
    position: fixed;
    top: 70px;
  }

  .actionWrapper {
    width: 100%;
    flex-wrap: wrap;
  }

  .actionWrapper > * {
    width: clamp(250px, 50%, 300px);
    margin-inline: auto;
  }
}
