.modalStyle {
  max-width: 780px;
}

.title {
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 16px;
}

.howItWorks {
  background-color: var(--grey50);
  border-radius: 10px;
  margin-block: 24px;
  padding: 24px;
}

.description {
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
}

.completionScaleBox {
  display: flex;
  gap: 32px;
}

.dataTitle {
  color: var(--grey700);
  font-size: var(--font-size-md);
  line-height: var(--line-height-16);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
}

.dataItemTitle {
  color: var(--grey800);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
}
@media screen and (max-width: 450px) {
  .completionScaleBox {
    flex-direction: column;
  }
}