.wrapper {
  margin: 1.5rem;
  height: 100%;
  max-height: calc(100vh - 19rem);
  background-color: var(--white);
  border-radius: 0.75rem;
  overflow: hidden;
  padding: 1.5rem;
}

.datePickerBtn {
  gap: 0.5rem;
  background-color: var(--grey50);
  border: 1px solid var(--grey400);
  color: var(--grey800);
  margin-bottom: 2rem;
  padding: 10px 24px;
}

.datePickerBtn:hover {
  background-color: var(--grey100);
  border-color: var(--grey500);
}

.loaderWrapper {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
  z-index: 1;
}

.calendarIconStyle {
  stroke-width: 1.5px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: calc(100% - 3.5rem);
  overflow-y: auto;
}

.list::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin: 0;
    padding-top: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    overflow-y: auto;
    padding-inline: 1rem;
  }
}