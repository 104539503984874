.subTitle {
  margin: 1rem 0 1rem;
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 40%;
}

.passwordHelper {
  display: flex;
  gap: 5px;
  align-items: center;
  align-self: flex-start;
  color: var(--grey600);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-16);
}

.showPassword {
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  align-self: flex-start;
  color: var(--grey700);
  cursor: auto;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-16);
}

.checkboxStyle {
  width: var(--icon-small);
  height: var(--icon-small);
  border: 1px solid var(--secondary500);
  cursor: pointer;
}

.checkIconStyle {
  transform: translate(1px);
}

.loader {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  inset: 0;
  position: absolute;
  z-index: 2;
}
@media screen and (max-width: 1024px) {
  .container {
    margin: 0;
    border-radius: 0;
    padding: 16px 0;
  }

  .form {
    max-width: 60%;
  }

  .saveBtn {
    width: 80%;
  }
}
@media screen and (max-width: 450px) {
  .header {
    padding: 0;
  }

  .title {
    font-size: var(--font-size-md);
    line-height: var(--line-height-16);
    padding-bottom: 12px;
  }

  .form {
    max-width: 100%;
  }

  .saveBtn {
    width: 100%;
  }

  .subTitle {
    margin: 0 0 1rem;
  }
}
