.fields {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% / 3);
  height: 54px;
  background-color: white;
  border: 1px solid var(--grey300);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.changeComponentWrapper {
  min-width: calc(100% - 40px);
  background-color: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 18px;
  bottom: calc(100% + 5px);
  box-shadow: 0px 5px 20px rgba(127, 122, 122, 0.1);
  padding: 20px;
  position: absolute;
  z-index: 10;
}

.label {
  max-width: 70%;
  color: var(--grey500);
  font-size: 12px;
  overflow: hidden;
  padding-left: 12px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.value {
  width: 100%;
  color: var(--grey900);
  font-size: 16px;
  overflow: hidden;
  padding-left: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  color: var(--grey600);
  left: 85%;
  position: absolute;
  top: 35%;
  transition: all 0.5s ease;
}

.invertedIcon {
  color: var(--primary600);
  transform: rotate(180deg);
  transition: all 0.5s ease;
}

.disabled {
  background-color: var(--grey50);
  pointer-events: none;
  user-select: none;
}

.disabled .label > .icon {
  display: none;
}
@media screen and (max-width: 768px) {
  .fields {
    flex-direction: column;
    gap: 10px;
  }

  .field {
    width: 100%;
  }
}
