.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-inline: 16px;
}

.sectionName {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: var(--line-height-15);
  margin-bottom: 14px;
}

.inputSelection {
  border-radius: 38px;
}

.horizontalLine {
  width: 100%;
  border-bottom: 1px solid var(--grey300);
}

.questionMarkInfo {
  width: 18px;
  height: 18px;
  cursor: pointer;
  stroke: var(--primary500);
}

.questionMarkInfo:hover {
  filter: brightness(0.8);
}

.modalContentStyle {
  display: flex;
  flex-direction: column;
  width: 675px;
}

.expandableSectionName {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 16px;
  align-items: center;
  color: var(--grey700);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
  margin-bottom: 14px;
}

.expandableWrapper {
  display: none;
}
@media screen and (max-width: 768px) {
  .questionMarkInfo {
    display: none;
  }

  .expandableWrapper {
    display: block;
  }
}
