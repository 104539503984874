.container {
  display: flex;
  flex-direction: column;
  margin: 24px 32px;
  height: calc(100vh - 240px);
  background-color: var(--white);
  border-radius: 12px;
  box-sizing: border-box;
  margin-right: 16px;
  overflow: hidden;
  overflow-y: auto;
  padding: 24px;
}

.container::-webkit-scrollbar {
  width: 4px;
}

.header {
  border-bottom: 1px solid var(--primary200);
  margin-bottom: 1.75rem;
  padding-bottom: 12px;
}

.title {
  color: var(--grey800);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
}

.noImage {
  width: 42px;
  height: 42px;
  color: var(--primary500);
}

.body {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: fit-content;
}

.accountDetails {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
}

.disconnectBtn {
  margin-top: 1.25rem;
  width: fit-content;
  background: transparent;
  color: var(--primary500);
  padding-left: 0;
}

.disconnectBtn:hover {
  background: transparent;
  color: var(--primary700);
}

.disconnectBtn:disabled {
  background-color: transparent;
  color: var(--primary300);
  pointer-events: none;
}

.accountName {
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.mail {
  color: var(--grey600);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-15);
}

.userPicture {
  width: 56px;
  height: 56px;
  background: lightgray 50% / cover no-repeat;
  border-radius: 50%;
  color: var(--white);
}

.googleIcon {
  width: var(--icon-small);
  height: var(--icon-small);
  background-color: white;
  border-radius: 50%;
  bottom: 10%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  left: 0;
  padding: 2px;
  position: absolute;
}

.phoneNumberInput {
  margin-top: 0.5rem;
  max-width: 40%;
}

.loaderWrapper {
  backdrop-filter: blur(2px);
  inset: 0;
  position: absolute;
}

.subTitle {
  color: var(--grey800);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi);
  line-height: var(--line-height-15);
  margin-bottom: 1.5rem;
}

.saveBtn {
  margin-top: 0.75rem;
  width: 12rem;
  box-sizing: border-box;
  padding: 10px 24px;
}

.accountInfo {
  display: flex;
  justify-content: space-between;
}

.linksWrapper {
  display: flex;
  gap: 2rem;
}

.linksWrapper>a {
  color: var(--primary500);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
}

.linksWrapper>a:hover {
  color: var(--primary600);
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 0;
    border-radius: 0;
    padding: 16px;
  }

  .phoneNumberInput {
    max-width: 60%;
  }
}

@media screen and (max-width: 450px) {
  .container {
    width: 100%;
    height: calc(100svh - 220px);
  }

  .accountInfo {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .linksWrapper {
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .header {
    padding: 0;
  }

  .title {
    font-size: var(--font-size-md);
    line-height: var(--line-height-16);
  }

  .disconnectBtn {
    width: auto;
    margin-left: 0;
  }

  .body {
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    width: 100%;
  }

  .phoneNumberInput {
    max-width: 80%;
  }
}