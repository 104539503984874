.textarea {
  min-height: 145px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.aiWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;
}

.aiWrapperOpen {
  position: static;
}

.textAreaStyle {
  padding-top: 0.825rem;
}

.textAreaStyle::-webkit-scrollbar {
  display: none;
}
