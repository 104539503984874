.modalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: fit-content;
  min-width: 60vw;
  max-height: 95vh;
  overflow: hidden;
  overflow-y: auto;
}

.body {
  height: 100%;
  min-height: 320px;
}

.stepper {
  color: var(--grey900);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-15);
}

.noBusinessProfiles {
  display: flex;
  margin: 3rem auto;
  max-width: 80%;
  height: 100%;
  color: var(--grey800);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.loader {
  width: 100vh;
  height: auto;
  backdrop-filter: blur(2px);
  inset: 0;
  position: fixed;
}

.summaryWrapper {
  max-width: 60vw;
}

@media screen and (max-width: 1440px) {
  .modalWrapper {
    max-width: 90vw;
    min-width: auto;
  }
}

@media screen and (max-width: 768px) {
  .modalWrapper {
    max-width: 99vw;
    position: relative;
    top: 0;
  }

}

@media screen and (max-width: 450px) {
  .modalWrapper {
    top: 0;
  }
}